import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { ConstructorComponent } from 'src/app/pages/slk-admin/common/constructor/component/constructor.component';
import { ConstructorModule } from 'src/app/pages/slk-admin/common/constructor/constructor.module';
import { ConstructedPageService } from 'src/app/pages/slk-admin/pages/constructed-pages/pages/services/constructed-page.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule } from 'src/app/modules/loading/loading.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    LoadingModule,
    ConstructorModule.inversion(ConstructedPageService),

    RouterModule.forChild([
      {
        path: ':company-id/:type/create-new',
        loadChildren: async () =>
          (await import('./crate-nev-vacancies.module'))
            .CreateVacanciesPageModule,
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ConstructorComponent,
            data: { headline: 'Create new ' },
          },
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [],
  providers: [],
})
export class CreateVacanciesPageModule {}
