import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstructorAbstractService } from '../constructor.abstract.service';
import { ConstructorService } from '../constructor.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogData } from 'src/app/pages/manage-users/component/success-invite/success-invite.component';
import { ErrorModalConstructorComponent } from './error-modal/error-modal.component';

@Component({
  selector: 'constructor-blocks',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.scss'],
})
export class ConstructorComponent implements OnInit {
  public headline: string = '';

  constructor(
    private readonly constructorAbstractService: ConstructorAbstractService,
    protected readonly constructorService: ConstructorService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const type = params.get('type');
      const page_id = params.get('page-id');
      const company_id = params.get('company-id');

      this.constructorAbstractService.configPageOnLoad(
        page_id,
        type,
        company_id
      );
    });

    this.route.data.subscribe((data) => {
      const { headline = '' } = data;
      this.headline = headline;
    });
  }

  public onSave() {
    const invalid = this.constructorService.checkValidity(
      this.constructorAbstractService.form
    );
    if (invalid) {
      this.dialog.open(ErrorModalConstructorComponent, {
        maxWidth: '590px',
        width: '100%',
      });
    } else {
      this.constructorAbstractService.onSave();
    }
  }

  public get type() {
    return this.constructorAbstractService.type;
  }

  public get loading() {
    return this.constructorAbstractService.loading;
  }
}
