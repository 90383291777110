import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import { ConstructedBlock } from 'src/app/entity/constructed-pages/constructed-block.entity';
import { ConstructedMetaInfo } from 'src/app/entity/constructed-pages/constructed-meta-info.entity';
import { ConstructedPage } from 'src/app/entity/constructed-pages/constructed-page.entity';
import { ConstructedPreview } from 'src/app/entity/constructed-pages/constructed-preview.entity';
import { ConstructedPageType } from 'src/app/enum/constructed-page-type.enum';
import { omitObject } from '../../pages/constructed-pages/pages/services/constructed-page.service';
import {
  FormTemplates,
  blocks,
} from './component/fields-control/fields-control.form.template';
import { PreviewBlockList } from './constructor.abstract.service';
import { ConstructedVacancy } from 'src/app/entity/constructed-pages/constructed-vacancy-info.entity';

@Injectable()
export class ConstructorService {
  public readonly blocks: Record<ConstructedPageType, PreviewBlockList[]> = {
    [ConstructedPageType.VACANCY]: [
      // {
      //   src: 'assets/site-tool-constructor/blog/main.png',
      //   type: FormTemplates.MAIN_BLOCK,
      // },
      // {
      //   src: 'assets/site-tool-constructor/vacancy/picture.png',
      //   type: FormTemplates.VACANCY_MAIN_BLOCK,
      // },
      {
        src: 'assets/site-tool-constructor/vacancy/picture.png',
        type: FormTemplates.VACANCY_BODY_BLOCK,
      },
      // {
      //   src: 'assets/site-tool-constructor/blog/picture.png',
      //   type: FormTemplates.BLOCK_WITH_IMAGE_LEFT,
      // },
      // {
      //   src: 'assets/site-tool-constructor/blog/picture.png',
      //   type: FormTemplates.VACANCY_INFO,
      // },
      // {
      //   src: 'assets/site-tool-constructor/blog/price.png',
      //   type: FormTemplates.PRICES_BLOCK,
      // },
      // {
      //   src: 'assets/site-tool-constructor/blog/you-may-also-like.png',
      //   type: FormTemplates.YOU_MAY_ALSO_LIKE,
      // },
    ],
    [ConstructedPageType.BLOG]: [
      {
        src: 'assets/site-tool-constructor/blog/main.png',
        type: FormTemplates.MAIN_BLOCK,
      },
      {
        src: 'assets/site-tool-constructor/blog/picture.png',
        type: FormTemplates.BLOCK_WITH_IMAGE_LEFT,
      },
      {
        src: 'assets/site-tool-constructor/blog/price.png',
        type: FormTemplates.PRICES_BLOCK,
      },
      {
        src: 'assets/site-tool-constructor/blog/you-may-also-like.png',
        type: FormTemplates.YOU_MAY_ALSO_LIKE,
      },
    ],
    [ConstructedPageType.LOCATION]: [
      {
        src: 'assets/site-tool-constructor/blog/main.png',
        type: FormTemplates.MAIN_BLOCK,
      },
      {
        src: 'assets/site-tool-constructor/blog/price.png',
        type: FormTemplates.PRICES_BLOCK,
      },
      {
        src: 'assets/site-tool-constructor/location/about-city.png',
        type: FormTemplates.ABOUT_CITY,
      },
      {
        src: 'assets/site-tool-constructor/location/about-us.png',
        type: FormTemplates.ABOUT_US,
      },
      {
        src: 'assets/site-tool-constructor/location/our-services.png',
        type: FormTemplates.OUR_SERVICES,
      },
      {
        src: 'assets/site-tool-constructor/location/twenty-four-hour.png',
        type: FormTemplates.TWENTY_FOUR_HOUR,
      },
      {
        src: 'assets/site-tool-constructor/location/testimonials.png',
        type: FormTemplates.TESTIMONIALS,
      },
    ],
  };

  public readonly initial_blocks = {
    [ConstructedPageType.BLOG]: [
      this.addBlock(FormTemplates.META_INFOS),
      this.addBlock(FormTemplates.MAIN_BLOCK),
    ],
    [ConstructedPageType.LOCATION]: [
      this.addBlock(FormTemplates.META_INFOS_LOCATION),
      this.addBlock(FormTemplates.MAIN_BLOCK),
    ],
  };

  public checkValidity(form: FormGroup[]) {
    const form_array = new FormArray([...form]);
    let error: string = '';

    for (const c of form_array.controls) {
      c.markAllAsTouched();

      function GetErrorMessage(errored_control_name: string) {
        const section_name = c.value.type_block.replace(/_/g, ' ');
        return `<p>
        <b>${errored_control_name.replace(/_/g, ' ')}</b>
        from
        <b>${c.value.headline ?? section_name}</b>
        can not be empty
        </p>`;
      }

      const blocks_array = form_array.controls.map(
        (elem) => elem.value.type_block
      );

      if (
        !blocks_array.some((e) => e === FormTemplates.MAIN_BLOCK) &&
        !blocks_array.some((e) => e === FormTemplates.VACANCY_INFO)
      ) {
        error = 'page must contain main block';
      }

      if (c.value.type_block !== 'you_may_also_like') {
        for (const [control_name, value] of Object.entries(c.controls)) {
          if (control_name === 'photo') {
            for (const [photo_c_name, photo_control] of Object.entries(
              (value as FormGroup).controls || {}
            )) {
              if (photo_c_name !== 'file' && photo_control.errors) {
                error = GetErrorMessage('photo ' + photo_c_name);
              }
            }
          }

          if (value.errors) {
            const error_message = GetErrorMessage(control_name);
            error = error_message;
          }
        }
      }
    }
    return error;
  }

  public addBlock(type: FormTemplates) {
    const block = blocks[type];

    const form = this.buildBlock(block);

    return form;
  }

  private buildBlock(block: {
    [key: string]: {
      value: any;
      validators: ((
        control: AbstractControl<any, any>
      ) => ValidationErrors | null)[];
    };
  }) {
    const form = new FormGroup({});

    for (let [key, value] of Object.entries(block || {})) {
      if (
        value.value === null ||
        typeof value.value === 'string' ||
        typeof value.value === 'boolean' ||
        typeof value.value === 'number'
      ) {
        form.setControl(key, new FormControl(value.value, value.validators));
      } else {
        const nested_form = this.buildBlock(value.value);
        form.setControl(key, nested_form);
      }
    }

    return form;
  }

  public parseFormData(form: FormGroup[]) {
    const { value } = new FormArray([...form]);

    const template: Omit<
      DeepOmit<
        ConstructedPage,
        | 'constructed_page_id'
        | 'constructed_photo_id'
        | 'constructed_page_company_id'
      >,
      'id'
    > = {
      blocks: [],
      is_posted: false,
      meta_info: {
        id: '',
        meta_tag_description: '',
        meta_tag_title: '',
        global_script: '',
        schema_script: '',
        url: '',
        categoric: '',
        date_when_will_post_blog: '',
        map_link: undefined,
        state: undefined,
        name: undefined,
        redirect_url: undefined,
      },
      post_date: 0,
      preview: {
        id: '',
        type_block: '',
        experience: '',
        headline: '',
        description: '',
        photo: {
          id: '',
          alt: '',
          title: '',
          file_id: '',
        },
        date: '',
      },
      type: ConstructedPageType.BLOG,
    };

    value.forEach((item, position_block) => {
      if (
        item.type_block === FormTemplates.META_INFOS ||
        item.type_block === FormTemplates.VACANCY_INFO ||
        item.type_block === FormTemplates.META_INFOS_LOCATION
      ) {
        template.meta_info = {
          ...omitObject<typeof item>(item, ['type_block']),
        };

        // if (item.type_block === FormTemplates.VACANCY_INFO) {
        //   template.preview = {
        //     headline: 'item.headline',
        //     description: 'item.description',
        //   } as ConstructedPreview;
        // }
      } else if (item.type_block === FormTemplates.VACANCY_MAIN_BLOCK) {
        template.preview = {
          headline: item.headline,
          description: item.description,
          experience: item.experience,
          photo: null,
        } as ConstructedPreview;
      } else if (item.type_block === FormTemplates.MAIN_BLOCK) {
        const block = {
          ...omitObject<typeof item>(item, [
            'constructed_page_id',
            'constructed_photo_id',
            'photo',
            '__typename',
            'type_block',
          ]),
          ...(item.photo &&
            (item.photo.file || item.photo.file_id) && {
              photo: {
                alt: item.photo.alt,
                title: item.photo.title,
                ...(item.photo.file && { file: item.photo.file }),
              },
            }),
        };
        template.preview = {
          headline: item.headline,
          description: item.description,
          photo: item.photo
            ? {
                alt: item.photo.alt,
                title: item.photo.title,
                ...(item.photo.file && { file: item.photo.file }),
              }
            : {},
        } as ConstructedPreview;

        template.blocks.push({
          ...block,
          position_block,
          type_block: item.type_block,
        });
      } else {
        template.blocks.push({
          ...(item.styles && { styles: JSON.stringify(item.styles) }),
          ...omitObject<typeof item>(item, [
            'photo',
            'styles',
            'constructed_page_id',
            'constructed_photo_id',
            '__typename',
          ]),
          position_block,
          ...(item.photo &&
            (item.photo.file || item.photo.file_id) && {
              photo: {
                alt: item.photo.alt,
                title: item.photo.title,
                ...(item.photo.file && { file: item.photo.file }),
              },
            }),
        });
      }
    });

    return template;
  }

  public async patchValue({
    block,
    type_block,
    form,
  }: {
    block: ConstructedMetaInfo | ConstructedBlock | ConstructedVacancy;
    type_block: FormTemplates;
    form: FormGroup[];
  }) {
    const create_form_group: FormGroup = await this.addBlock(type_block);

    create_form_group.reset();
    create_form_group.patchValue({ type_block });

    for (let [key, value] of Object.entries(block)) {
      if (!(key in create_form_group.value)) {
        create_form_group.addControl(key, new FormControl(value));
      } else if (key === 'styles') {
        create_form_group.patchValue({ [key]: JSON.parse(value) });
      } else if (key === 'photo') {
        create_form_group.patchValue({ [key]: value });

        if (value?.file_id) {
          const photo = create_form_group.get('photo') as FormGroup;
          photo.registerControl('file_id', new FormControl(value?.file_id));
        }
      } else create_form_group.patchValue({ [key]: value });
    }

    form.push(create_form_group);
  }
}
