import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ConstructedPage } from 'src/app/entity/constructed-pages/constructed-page.entity';
import { reversed_lover_case_constructed_page_type } from 'src/app/enum/constructed-page-type.enum';
import {
  IUpdateConstructedPageDto,
  IUpdateConstructedPageResponse,
  UPDATE_CONSTRUCTED_PAGE,
} from 'src/app/pages/slk-admin/apollo/mutation/update-constructed-page.mutation';
import { FormTemplates } from 'src/app/pages/slk-admin/common/constructor/component/fields-control/fields-control.form.template';
import { ConstructorService } from 'src/app/pages/slk-admin/common/constructor/constructor.service';
import { ConstructedPageService, omitObject } from './constructed-page.service';
@Injectable()
export class UpdateConstructedPageService extends ConstructedPageService {
  public getBlogByIdResponse: ConstructedPage;

  constructor(
    protected override apollo: Apollo,
    protected override constructorService: ConstructorService,
    protected override router: Router
  ) {
    super(apollo, constructorService, router);
  }

  public override async configPageOnLoad(
    id: string,
    type: keyof typeof reversed_lover_case_constructed_page_type,
    company_id: string
  ) {
    this.loading = true;

    if (type) {
      this.type = reversed_lover_case_constructed_page_type[type];
      this.handleInitialBlocks(type);
    }

    if (company_id) this.company_id = company_id;

    this.form = [];
    await this.getPageById(id).subscribe(async ({ data, loading }) => {
      this.loading = loading;

      this.getBlogByIdResponse = { ...(data?.getConstructedPageById || {}) };

      const constructed_page = data.getConstructedPageById;
      // ----
      const handle_meta_type = () => {
        switch (type) {
          case 'blog':
            return [FormTemplates.META_INFOS];
          case 'location':
            return [FormTemplates.META_INFOS_LOCATION];
          case 'vacancy':
            return [FormTemplates.VACANCY_INFO];
        }
      };

      // this.constructorService.patchValue({
      //   block: constructed_page.meta_info,
      //   type_block: handle_meta_type(),
      //   form: this.form,
      // });

      for (let type of handle_meta_type()) {
        this.constructorService.patchValue({
          block: constructed_page.meta_info,
          type_block: type,
          form: this.form,
        });
      }

      if (type === 'vacancy') {
        this.constructorService.patchValue({
          block: {
            headline: constructed_page.preview.headline,
            description: constructed_page.preview.description,
            experience: constructed_page.preview.experience,
          },
          type_block: FormTemplates.VACANCY_MAIN_BLOCK,
          form: this.form,
        });
      }
      console.log(constructed_page.blocks);

      for (let group of constructed_page.blocks) {
        this.constructorService.patchValue({
          block: group,
          type_block: group.type_block as FormTemplates,
          form: this.form,
        });
      }
    });
  }

  public override onSave() {
    this.loading = true;
    const id = this.getBlogByIdResponse.id;
    const { blocks, preview, meta_info } =
      this.constructorService.parseFormData(this.form);

    const handle_meta_info = omitObject(meta_info, [
      '__typename',
      'constructed_page_id',
      'constructed_photo_id',
      'id',
    ]);
    const delete_blocks_ids = this.getBlogByIdResponse.blocks
      .filter((block) => {
        return block.id && !blocks.some((s_block) => s_block.id === block.id);
      })
      .map((block) => block.id);

    this.apollo
      .mutate<IUpdateConstructedPageResponse, IUpdateConstructedPageDto>({
        mutation: UPDATE_CONSTRUCTED_PAGE,
        variables: {
          constructedPage: {
            id,
            blocks,
            delete_blocks_ids,
            preview,
            meta_info: handle_meta_info,
          },
        },
      })
      .subscribe({
        complete: () => {
          this.router.navigate([
            'slk-admin',
            this.company_id,
            this.type.toLowerCase(),
          ]);
        },
        next: ({ loading, data }) => {
          this.loading = loading;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
