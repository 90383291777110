import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import moment from 'moment';
import { map } from 'rxjs';
import { UserWithSchedule } from 'src/app/entity/schedule/user-with-schedules.entity';
import { TimeTemplate } from 'src/app/entity/time-template.entity';
import { UserRole } from 'src/app/enum/user-role.enum';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';
import {
  APPROVE_USER_SCHEDULE_REQUEST,
  IApproveUserScheduleRequestDto,
  IApproveUserScheduleRequestResponse,
} from '../../../apollo/mutation/approve-user-schedule-request.mutation';
import {
  CREATE_OR_UPDATE_MANY_USERS_SCHEDULES,
  ICreateOrUpdateManyUsersSchedulesDto,
  ICreateOrUpdateManyUsersSchedulesResponse,
} from '../../../apollo/mutation/create-or-update-many-users-schedules.mutation';
import {
  CREATE_OR_UPDATE_USER_INFO,
  ICreateOrUpdateUserInfoDto,
  ICreateOrUpdateUserInfoResponse,
} from '../../../apollo/mutation/create-or-update-user-info.mutation';
import {
  CREATE_OR_UPDATE_USER_NOTE,
  ICreateOrUpdateUserNoteDto,
  ICreateOrUpdateUserNoteResponse,
} from '../../../apollo/mutation/create-or-update-user-note.mutation';
import {
  CREATE_TIME_TEMPLATE,
  ICreateTimeTemplateDto,
  ICreateTimeTemplateResponse,
} from '../../../apollo/mutation/create-time-template.mutation';
import {
  DECLINE_USER_SCHEDULE_REQUEST,
  IDeclineUserScheduleRequestDto,
  IDeclineUserScheduleRequestResponse,
} from '../../../apollo/mutation/decline-user-schedule-request.mutation';
import {
  DELETE_MANY_USER_INFO,
  IDeleteManyUserInfoDto,
  IDeleteManyUserInfoResponse,
} from '../../../apollo/mutation/delete-many-user-info.mutation';
import {
  DELETE_MANY_USER_SCHEDULES,
  IDeleteManyUserSchedulesDto,
  IDeleteManyUserSchedulesResponse,
} from '../../../apollo/mutation/delete-many-user-schedules.mutation';
import {
  DELETE_TIME_TEMPLATE,
  IDeleteTimeTemplateDto,
  IDeleteTimeTemplateResponse,
} from '../../../apollo/mutation/delete-time-template.mutation';
import {
  IUpdateTimeTemplateDto,
  IUpdateTimeTemplateResponse,
  UPDATE_TIME_TEMPLATE,
} from '../../../apollo/mutation/update-time-template.mutation';
import {
  GET_ALL_TIME_TEMPLATES,
  IGetAllTimeTemplatesResponse,
} from '../../../apollo/query/get-all-time-templates.query';
import {
  GET_USERS_WITH_SCHEDULE,
  IGetUsersWithScheduleDto,
  IGetUsersWithScheduleResponse,
} from '../../../apollo/query/get-users-with-schedule.query';

@Injectable()
export class ScheduleService {
  public week_index = 0;
  public roles = [UserRole.TECHNICIAN];
  public getUsersQuery: QueryRef<
    IGetUsersWithScheduleResponse,
    IGetUsersWithScheduleDto
  >;
  public disabled = true;

  private working_time: boolean | undefined;
  private states: string[];

  private current_week: moment.Moment = moment()
    .clone()
    .startOf('isoWeek')
    .add(this.week_index, 'week');

  private dayFromWeek: moment.Moment = this.current_week
    .clone()
    .startOf('isoWeek');

  private dayToWeek: moment.Moment = this.current_week.clone().endOf('isoWeek');

  private search_value = '';

  public users: UserWithSchedule[] = [];
  public loading = false;

  constructor(
    private readonly apollo: Apollo,
    private readonly alertService: AlertService
  ) {}

  public get acceptable_edit_roles() {
    return [UserRole.ADMIN, UserRole.MAIN_DISPATCHER, UserRole.TECHNICIAN];
  }

  public get currentWeek(): moment.Moment {
    return this.current_week;
  }

  public get weekEndDate(): string {
    return this.dayToWeek.format('MMM DD');
  }

  public get endWeek(): moment.Moment {
    return this.dayToWeek;
  }

  public get weekStartDate(): string {
    return this.dayFromWeek.format('MMM DD');
  }

  public get startWeek(): moment.Moment {
    return this.dayFromWeek;
  }

  public set setRoles(roles: UserRole[]) {
    this.roles = roles;
    this.getUsersQuery.refetch(this.variables);
  }

  public get getRoles() {
    return this.roles;
  }

  private get variables(): IGetUsersWithScheduleDto {
    return {
      userWithScheduleDto: {
        from: this.dayFromWeek.clone().unix(),
        to: this.dayToWeek.clone().unix(),
        search_value: this.search_value,
        role: this.roles,
        ...(typeof this.working_time === 'boolean' && {
          is_available: this.working_time,
        }),
        ...(this.states &&
          this.states?.length > 0 && { locations: this.states }),
      },
    };
  }

  public setFilters(working: boolean | undefined, state: string[]) {
    this.working_time = working;
    this.states = state;

    this.getUsersQuery.refetch(this.variables);
  }

  public set setSearchValue(value: string) {
    this.search_value = value;
    this.getUsersQuery.refetch(this.variables);
  }

  public setWeekIndex(week_index: number) {
    this.current_week = this.current_week.clone().add(week_index, 'week');
    this.dayFromWeek = this.current_week.clone().startOf('isoWeek');
    this.dayToWeek = this.current_week.clone().endOf('isoWeek');

    this.getUsersQuery.refetch(this.variables);
  }

  public formatDate(time: string, dayIndex: number = 0) {
    const currentDate = this.dayFromWeek.clone();
    currentDate.add(dayIndex, 'day');
    const formatted_date = currentDate.format('DD/MM/YYYY');
    const date_time = formatted_date + ' ' + time;
    const formatted = moment(date_time, 'DD/MM/YYYY hh:mm a').unix();
    return formatted;
  }

  public getUsersWithSchedule(start_loading: boolean = false) {
    if (start_loading) {
      this.loading = true;
    }
    this.getUsersQuery = this.apollo.watchQuery<
      IGetUsersWithScheduleResponse,
      IGetUsersWithScheduleDto
    >({
      query: GET_USERS_WITH_SCHEDULE,
      variables: this.variables,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

    this.getUsersQuery.valueChanges
      .pipe(
        map(({ data, networkStatus }) => ({
          user: data?.getUsersWithSchedule || [],
          networkStatus,
        }))
      )
      .subscribe(({ user, networkStatus }) => {
        this.disabled = !(networkStatus === 7);

        this.users = user;

        if (start_loading) {
          this.loading = this.disabled;
        }
      });
  }
  public createOrUpdateTechInfo(
    user_infos: ICreateOrUpdateUserInfoDto['user_infos'],
    user_id: string
  ) {
    this.apollo
      .mutate<ICreateOrUpdateUserInfoResponse, ICreateOrUpdateUserInfoDto>({
        mutation: CREATE_OR_UPDATE_USER_INFO,
        variables: { user_infos },
        update(cache, { data }) {
          cache.modify({
            id: `UserWithSchedule:${user_id}`,
            fields: {
              info: () => {
                return data!.createOrUpdateUserInfo.map((item) => ({
                  __ref: `UserInfo:${item.id}`,
                }));
              },
            },
          });
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Info successfully saved',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }
  public deleteManyUserInfo(ids: string[], user_id: string) {
    this.apollo
      .mutate<IDeleteManyUserInfoResponse, IDeleteManyUserInfoDto>({
        mutation: DELETE_MANY_USER_INFO,
        variables: { ids },
        update(cache, { data }) {
          cache.modify({
            id: `UserWithSchedule:${user_id}`,
            fields: {
              info: (exist: readonly { __ref: string }[] = []) => {
                return exist.filter(
                  (ref) =>
                    !data!.deleteManyUserInfo.some((id) =>
                      ref.__ref.includes(id)
                    )
                );
              },
            },
          });
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Info successfully deleted',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public createOrUpdateManyUsersSchedules(
    user_schedules: ICreateOrUpdateManyUsersSchedulesDto['user_schedules'],
    user: UserWithSchedule
  ) {
    this.apollo
      .mutate<
        ICreateOrUpdateManyUsersSchedulesResponse,
        ICreateOrUpdateManyUsersSchedulesDto
      >({
        mutation: CREATE_OR_UPDATE_MANY_USERS_SCHEDULES,
        variables: { user_schedules },
        update: () => {
          this.getUsersWithSchedule();
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Schedule successfully saved',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public deleteManyUsersSchedules(ids: string[], user: UserWithSchedule) {
    this.apollo
      .mutate<IDeleteManyUserSchedulesResponse, IDeleteManyUserSchedulesDto>({
        mutation: DELETE_MANY_USER_SCHEDULES,
        variables: { ids },
        update: () => {
          this.getUsersWithSchedule();
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Schedule successfully deleted',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public getAllTimeTemplates() {
    return this.apollo
      .watchQuery<IGetAllTimeTemplatesResponse>({
        query: GET_ALL_TIME_TEMPLATES,
      })
      .valueChanges.pipe(map((r) => r.data.getAllTimeTemplates));
  }

  public updateTimeTemplate(time_template: TimeTemplate) {
    this.apollo
      .mutate<IUpdateTimeTemplateResponse, IUpdateTimeTemplateDto>({
        mutation: UPDATE_TIME_TEMPLATE,
        variables: { time_template },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Time template successfully saved',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public deleteTimeTemplate(id: string) {
    this.apollo
      .mutate<IDeleteTimeTemplateResponse, IDeleteTimeTemplateDto>({
        mutation: DELETE_TIME_TEMPLATE,
        variables: { id },
        update(cache, { data }) {
          cache.modify({
            fields: {
              getAllTimeTemplates(exist: readonly { __ref: string }[]) {
                return exist.filter(
                  (ex) => !ex.__ref.includes(data!.deleteTimeTemplate)
                );
              },
            },
          });
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Time template successfully deleted',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public createTimeTemplate(
    time_template: ICreateTimeTemplateDto['time_template']
  ) {
    this.apollo
      .mutate<ICreateTimeTemplateResponse, ICreateTimeTemplateDto>({
        mutation: CREATE_TIME_TEMPLATE,
        variables: { time_template },
        update(cache, { data }) {
          cache.modify({
            fields: {
              getAllTimeTemplates(exist) {
                return [
                  ...exist,
                  { __ref: `TimeTemplate:${data!.createTimeTemplate.id}` },
                ];
              },
            },
          });
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Time template successfully saved',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public createOrUpdateUserNote(
    user_note: ICreateOrUpdateUserNoteDto['user_note']
  ) {
    this.apollo
      .mutate<ICreateOrUpdateUserNoteResponse, ICreateOrUpdateUserNoteDto>({
        mutation: CREATE_OR_UPDATE_USER_NOTE,
        variables: { user_note },
        update: (cache, { data }) => {
          cache.modify({
            id: `UserWithSchedule:${user_note.user_id}`,
            fields: {
              notes() {
                return [
                  { __ref: `UserNote:${data?.createOrUpdateUserNote.id}` },
                ];
              },
            },
          });
        },
      })
      .subscribe({
        complete: () =>
          this.alertService.alert({
            type: 'success',
            message: 'Notes successfully saved',
          }),
        error: (e) =>
          this.alertService.alert({ type: 'error', message: e.message }),
      });
  }

  public approveUserScheduleRequest(
    request_ids: IApproveUserScheduleRequestDto['request_ids']
  ) {
    this.apollo
      .mutate<
        IApproveUserScheduleRequestResponse,
        IApproveUserScheduleRequestDto
      >({
        mutation: APPROVE_USER_SCHEDULE_REQUEST,
        variables: { request_ids },
      })
      .subscribe({
        complete: () => {
          this.getUsersWithSchedule();
          this.alertService.alert({
            type: 'success',
            message: 'Request approved!',
          });
        },
      });
  }

  public declineUserScheduleRequest(
    request_ids: IDeclineUserScheduleRequestDto['request_ids']
  ) {
    this.apollo
      .mutate<
        IDeclineUserScheduleRequestResponse,
        IDeclineUserScheduleRequestDto
      >({
        mutation: DECLINE_USER_SCHEDULE_REQUEST,
        variables: { request_ids },
      })
      .subscribe({
        complete: () => {
          this.getUsersWithSchedule();
          this.alertService.alert({
            type: 'success',
            message: 'Request removed!',
          });
        },
      });
  }
}
