import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DirectiveModule } from 'src/app/directive/directive.module';
import { IconsModule } from 'src/app/modules/icons/icons.module';
import { UploadModule } from 'src/app/modules/ui-kit/upload/upload.module';
import { DropDownModule } from '../../../dropdown/dropdown.module';
import { BlockWithImageFieldsComponent } from './components/blogs/block-with-image-fields/block-with-image-fields.component';
import { StyleFieldsComponent } from './components/blogs/block-with-image-fields/style-fields/style-fields.component';
import { ImageFieldsComponent } from './components/blogs/image-fields/image-fields.component';
import { MainBlockFieldsComponent } from './components/blogs/main-block-fields/main-block-fields.component';
import { MetaTagAndScriptsFieldsComponent } from './components/blogs/meta-tag-and-scripts/meta-tag-and-scripts-fields.component';
import { PricesBlockFieldsComponent } from './components/blogs/prices-block-fields/prices-block-fields.component';
import { StyleFieldsPriceComponent } from './components/blogs/prices-block-fields/style-fields-price/style-fields-price.component';
import { YouMayAlsoLikeFieldsComponent } from './components/blogs/you-may-also-like/you-may-also-like.component';
import { AboutCityFieldsComponent } from './components/locations/about-city-fields/about-city-fields.component';
import { AboutUsFieldsComponent } from './components/locations/about-us-fields/about-us-fields.component';
import { MetaInfosFieldsComponent } from './components/locations/meta-infos-fields/meta-infos-fields.component';
import { OurServicesFieldsComponent } from './components/locations/our-services-fields/our-services-fields.component';
import { TwentyFourHourFieldsComponent } from './components/locations/twenty-four-hour-fields/twenty-four-hour-fields.component';
import { UploadViewComponent } from './components/upload-view/upload-view.component';
import { FieldsControl } from './fields-control.component';
import { GetImageUrlByPhotoIDPipe } from '../../pipe/get-image-url-by-photo-id.pipe';
import { TestimonialsFieldsComponent } from './components/locations/testimonials-fields/testimonials-fields.component';
import { VacanciesMetaInfoComponent } from './components/vacancies/vacancies-meta-info/vacancies-meta-info.component';
import { VacancyMainBlockFieldsComponent } from './components/vacancies/vacancy-main-block-fields/vacancy-main-block-fields.component';
import { BodyBlockFieldsComponent } from './components/vacancies/body-block-fields/body-block-fields.component';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    DirectiveModule,
    IconsModule,
    DropDownModule,
    UploadModule,
    GetImageUrlByPhotoIDPipe,
  ],
  declarations: [
    BodyBlockFieldsComponent,
    VacancyMainBlockFieldsComponent,
    VacanciesMetaInfoComponent,
    TestimonialsFieldsComponent,
    YouMayAlsoLikeFieldsComponent,
    MetaTagAndScriptsFieldsComponent,
    PricesBlockFieldsComponent,
    FieldsControl,
    MainBlockFieldsComponent,
    BlockWithImageFieldsComponent,
    ImageFieldsComponent,
    StyleFieldsComponent,
    StyleFieldsPriceComponent,
    AboutCityFieldsComponent,
    TwentyFourHourFieldsComponent,
    AboutUsFieldsComponent,
    OurServicesFieldsComponent,
    MetaInfosFieldsComponent,
    UploadViewComponent,
  ],
  exports: [
    YouMayAlsoLikeFieldsComponent,
    MetaTagAndScriptsFieldsComponent,
    PricesBlockFieldsComponent,
    FieldsControl,
    MainBlockFieldsComponent,
    BlockWithImageFieldsComponent,
    ImageFieldsComponent,
    StyleFieldsComponent,
    StyleFieldsPriceComponent,
    AboutCityFieldsComponent,
    TwentyFourHourFieldsComponent,
    AboutUsFieldsComponent,
    OurServicesFieldsComponent,
    MetaInfosFieldsComponent,
  ],
})
export class FieldsControlModule {}
