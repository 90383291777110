import { Component, Input } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';
interface MainBlockData {
  headline: string;
  description: string;
}
@Component({
  selector: 'vacancy-main-block',
  templateUrl: './vacancy-main-block.component.html',
  styleUrls: ['./vacancy-main-block.component.scss'],
})
export class VacancyMainBlockComponent {
  @Input() data: MainBlockData;

  get description() {
    return this.data.description?.split('\n') || [];
  }
}
