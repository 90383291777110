<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Headline </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type headline here"
      formControlName="headline"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Description </mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      placeholder="Type description here"
      formControlName="description"
    ></textarea>
  </mat-form-field>
  <mat-form-field>
    <mat-label> Experience </mat-label>
    <input
      cdkTextareaAutosize
      matInput
      placeholder="Type description here"
      formControlName="experience"
    />
  </mat-form-field>
</form>
