import { FormGroup } from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ConstructedPageType } from 'src/app/enum/constructed-page-type.enum';
import { IGetConstructedPageByIdResponse } from '../../apollo/query/get-constructed-page-by-id.query';
import {
  IGetConstructedPagesDto,
  IGetConstructedPagesResponse,
} from '../../apollo/query/get-constructed-pages';
import { FormTemplates } from './component/fields-control/fields-control.form.template';

export abstract class ConstructorAbstractService {
  public abstract loading: boolean;
  public abstract readonly type: ConstructedPageType;
  public abstract readonly id: string;
  public abstract readonly company_id: string;

  public abstract readonly blocks_preview: PreviewBlockList[];

  public abstract form: FormGroup[];
  public abstract configPageOnLoad(
    id: string | null,
    type: string | null,
    company_id: string | null,
    load_blocks?: boolean | null
  ): void;
  public abstract onSave(): void;
  public abstract getAllItems(
    variables: IGetConstructedPagesDto
  ): QueryRef<IGetConstructedPagesResponse, IGetConstructedPagesDto>;
  public abstract removeItem(id: string): void;
  public abstract publish(is_posted: boolean, id: string): void;
  public abstract getPageById(
    id: string
  ): Observable<ApolloQueryResult<IGetConstructedPageByIdResponse>>;
}

export interface IHeadlines {
  button_create: string;
  edit: string;
  create: string;
  previews: string;
}

export interface PreviewBlockList {
  src: string;
  type: FormTemplates;
}
