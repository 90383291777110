import { Component } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'vacancies_meta_info',
  templateUrl: './vacancies-meta-info.component.html',
  styleUrls: ['./vacancies-meta-info.component.scss'],
})
export class VacanciesMetaInfoComponent {
  constructor(public readonly controlContainer: ControlContainer) {}

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control;
  }

  public get country(): string {
    return this.controlContainer.control?.value.country;
  }
}
