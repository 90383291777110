import { Component } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'body_block_fields_component',
  templateUrl: './body-block-fields.component.html',
  styleUrls: ['./body-block-fields.component.scss'],
})
export class BodyBlockFieldsComponent {
  constructor(public readonly controlContainer: ControlContainer) {}
  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control;
  }
}
