<div class="wrap">
  <div>
    <div>
      <h1>{{ data.headline }}</h1>

      <hr class="hrYellow" />
    </div>
  </div>

  <div class="coverText">
    <p class="text" *ngFor="let text of description" [innerHTML]="text"></p>
  </div>
</div>
