import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConstructorAbstractService } from '../constructor.abstract.service';

@Injectable()
export class ConstructedPageExistGuard {
  constructor(
    private readonly constructorAbstractService: ConstructorAbstractService
  ) {}
  canActivate(route: ActivatedRouteSnapshot) {
    return new Observable<boolean>((observer) => {
      const id = route.paramMap.get('page-id');
      if (id) {
        this.constructorAbstractService.getPageById(id).subscribe({
          next: () => {
            observer.next(true);
            observer.complete();
          },
          error: () => {
            observer.next(false);
            observer.complete();
          },
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
