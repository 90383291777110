import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum FormTemplates {
  VACANCY_INFO = 'vacancy_info',
  VACANCY_MAIN_BLOCK = 'vacancy_main_block',
  VACANCY_BODY_BLOCK = 'vacancy_body_block',
  BLOCK_WITH_IMAGE_LEFT = 'block_with_image_left',
  MAIN_BLOCK = 'main_block',
  PRICES_BLOCK = 'prices_block',
  YOU_MAY_ALSO_LIKE = 'you_may_also_like',
  OUR_SERVICES = 'our_services',
  ABOUT_US = 'about_us',
  ABOUT_CITY = 'about_city',
  TWENTY_FOUR_HOUR = 'hour_24_services',
  META_INFOS = 'meta_infos',
  META_INFOS_LOCATION = 'meta_infos_location',
  TESTIMONIALS = 'testimonials',
}

export const blocks = {
  [FormTemplates.META_INFOS]: {
    type_block: {
      value: FormTemplates.META_INFOS,
      validators: [],
    },
    meta_tag_description: {
      value: '',
      validators: [Validators.required],
    },
    meta_tag_title: {
      value: '',
      validators: [Validators.required],
    },
    global_script: {
      value: '',
      validators: [],
    },
    schema_script: {
      value: '',
      validators: [],
    },
    url: {
      value: '',
      validators: [Validators.required],
    },
    categoric: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.VACANCY_MAIN_BLOCK]: {
    type_block: {
      value: FormTemplates.VACANCY_MAIN_BLOCK,
      validators: [],
    },
    headline: {
      value: 'vacancy headline',
      validators: [Validators.required],
    },
    description: {
      value: '',
      validators: [Validators.required],
    },
    experience: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.VACANCY_BODY_BLOCK]: {
    type_block: {
      value: FormTemplates.VACANCY_BODY_BLOCK,
      validators: [],
    },
    headline: {
      value: 'body headline',
      validators: [],
    },
    description: {
      value: 'asdasdasd',
      validators: [],
    },
    first_list: {
      value: 'asdasdasd',
      validators: [],
    },
    body_text: {
      value: 'asdasdasd',
      validators: [],
    },
    list: {
      value: '',
      validators: [],
    },
    last_text: {
      value: '',
      validators: [],
    },
    last_list: {
      value: '',
      validators: [],
    },
    photo: {
      value: {
        file: {
          value: null,
          validators: [],
        },
        alt: {
          value: '',
          validators: [],
        },
        title: {
          value: '',
          validators: [],
        },
      },
      validators: [],
    },
    styles: {
      value: {
        add_top_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        add_bottom_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        first_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        main_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        last_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        body_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        turn_padding_top: {
          value: true,
          validators: [Validators.required],
        },
        turn_padding_bottom: {
          value: true,
          validators: [Validators.required],
        },
        first_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        body_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        last_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        background_exists: {
          value: false,
          validators: [Validators.required],
        },
        first_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        main_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        last_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.VACANCY_INFO]: {
    type_block: {
      value: FormTemplates.VACANCY_INFO,
      validators: [],
    },
    meta_tag_description: {
      value: '',
      validators: [Validators.required],
    },
    meta_tag_title: {
      value: '',
      validators: [Validators.required],
    },
    // vacancy_title: {
    //   value: '',
    //   validators: [Validators.required],
    // },
    // vacancy_description: {
    //   value: '',
    //   validators: [Validators.required],
    // },
    salary: {
      value: '',
      validators: [],
    },
    country: {
      value: '',
      validators: [Validators.required],
    },
    state: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.MAIN_BLOCK]: {
    type_block: {
      value: FormTemplates.MAIN_BLOCK,
      validators: [Validators.required],
    },
    headline: {
      value: 'Headline',
      validators: [Validators.required],
    },
    description: {
      value: '',
      validators: [],
    },
    photo: {
      value: {
        file: {
          value: null,
          validators: [Validators.required],
        },
        alt: {
          value: '',
          validators: [Validators.required],
        },
        title: {
          value: '',
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.BLOCK_WITH_IMAGE_LEFT]: {
    type_block: {
      value: FormTemplates.BLOCK_WITH_IMAGE_LEFT,
      validators: [],
    },
    headline: {
      value: 'Headline',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
    first_list: {
      value: '',
      validators: [],
    },
    body_text: {
      value: '',
      validators: [],
    },
    list: {
      value: '',
      validators: [],
    },
    last_text: {
      value: '',
      validators: [],
    },
    last_list: {
      value: '',
      validators: [],
    },
    photo: {
      value: {
        file: {
          value: null,
          validators: [],
        },
        alt: {
          value: '',
          validators: [],
        },
        title: {
          value: '',
          validators: [],
        },
      },
      validators: [],
    },
    styles: {
      value: {
        add_top_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        add_bottom_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        first_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        main_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        last_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        body_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        turn_padding_top: {
          value: true,
          validators: [Validators.required],
        },
        turn_padding_bottom: {
          value: true,
          validators: [Validators.required],
        },
        first_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        body_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        last_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        background_exists: {
          value: false,
          validators: [Validators.required],
        },
        first_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        main_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        last_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.PRICES_BLOCK]: {
    type_block: {
      value: FormTemplates.PRICES_BLOCK,
      validators: [],
    },
    headline: {
      value: '',
      validators: [Validators.required],
    },
    description: {
      value: '',
      validators: [Validators.required],
    },
    list: {
      value: '',
      validators: [Validators.required],
    },
    body_text: {
      value: '',
      validators: [Validators.required],
    },
    last_text: {
      value: '',
      validators: [Validators.required],
    },
    styles: {
      value: {
        add_top_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        add_bottom_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.YOU_MAY_ALSO_LIKE]: {
    type_block: {
      value: FormTemplates.YOU_MAY_ALSO_LIKE,
      validators: [],
    },
    headline: {
      value: 'You may also like',
      validators: [Validators.required],
    },
    list: {
      value:
        'the-benefits-of-hiring-a-professional-home-security-company\nthe-benefits-of-hiring-a-professional-home-security-company\nthe-benefits-of-hiring-a-professional-home-security-company',
      validators: [Validators.required],
    },
  },
  [FormTemplates.META_INFOS_LOCATION]: {
    type_block: {
      value: FormTemplates.META_INFOS_LOCATION,
      validators: [],
    },
    meta_tag_title: {
      value: '',
      validators: [Validators.required],
    },
    meta_tag_description: {
      value: '',
      validators: [Validators.required],
    },
    global_script: {
      value: '',
      validators: [],
    },
    schema_script: {
      value: '',
      validators: [],
    },
    name: {
      value: '',
      validators: [Validators.required],
    },
    state: {
      value: '',
      validators: [Validators.required],
    },
    url: {
      value: '',
      validators: [Validators.required],
    },

    map_link: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.ABOUT_US]: {
    type_block: {
      value: FormTemplates.ABOUT_US,
      validators: [],
    },
    headline: {
      value: 'A few words about Sure Lock & Key',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.ABOUT_CITY]: {
    type_block: {
      value: FormTemplates.ABOUT_CITY,
      validators: [],
    },
    headline: {
      value: '',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.OUR_SERVICES]: {
    type_block: {
      value: FormTemplates.OUR_SERVICES,
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
    list: {
      value: '',
      validators: [Validators.required],
    },
  },
  [FormTemplates.TWENTY_FOUR_HOUR]: {
    type_block: {
      value: FormTemplates.TWENTY_FOUR_HOUR,
      validators: [],
    },
    headline: {
      value: '',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
    list: {
      value: `Lock repair, replacement, and installation \nCar key copy and duplication \nHome and house lockout services \nHome alarm systems \nEmergency car lockout services \nMotorcycle unlock services \nBroken key extraction \nRekeying services`,
      validators: [],
    },
  },
  [FormTemplates.TESTIMONIALS]: {
    type_block: {
      value: FormTemplates.TESTIMONIALS,
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
  },
};

export const block_forms = {
  [FormTemplates.META_INFOS]: {
    type_block: new FormControl(FormTemplates.META_INFOS),
    meta_tag_description: new FormControl('', Validators.required),
    meta_tag_title: new FormControl('', Validators.required),
    global_script: new FormControl(''),
    schema_script: new FormControl(''),
    url: new FormControl('', Validators.required),
    categoric: new FormControl('', Validators.required),
  },
  [FormTemplates.MAIN_BLOCK]: {
    type_block: new FormControl(FormTemplates.MAIN_BLOCK),
    headline: new FormControl('Headline', Validators.required),
    description: new FormControl('', Validators.required),
    photo: new FormGroup({
      file: new FormControl(null, Validators.required),
      alt: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
    }),
  },
  [FormTemplates.BLOCK_WITH_IMAGE_LEFT]: {
    type_block: new FormControl(FormTemplates.BLOCK_WITH_IMAGE_LEFT),
    headline: new FormControl('Headline', Validators.required),
    description: new FormControl(
      'Lorem ipsum io at, ut hic magnam earum tenetur praesentium distinctio placeat nihil amet id? Alias obcaecati fugit iusto atque?',
      Validators.required
    ),
    first_list: new FormControl(
      'Lorem ipsum \ndolor sit \namet consectetur \nadipisicing elit',
      Validators.required
    ),
    body_text: new FormControl(
      'Lorem ipsum dolor sit amet cconsequatur harum quaerat repellendus tempora, odio at, ut hic magnam earum tenetur praesentium distinctio placeat nihil amet id?',
      Validators.required
    ),
    list: new FormControl(
      'Lorem ipsum \ndolor sit \namet consectetur \nadipisicing elit',
      Validators.required
    ),
    last_text: new FormControl('Lorem ipsum', Validators.required),
    last_list: new FormControl(
      'Lorem ipsum \ndolor sit \namet consectetur \nadipisicing elit',
      Validators.required
    ),
    photo: new FormGroup({
      file: new FormControl(null),
      alt: new FormControl(''),
      title: new FormControl(''),
    }),
    styles: new FormGroup({
      add_top_need_more_info_button: new FormControl(
        false,
        Validators.required
      ),
      add_bottom_need_more_info_button: new FormControl(
        false,
        Validators.required
      ),
      first_list_number_list: new FormControl(false, Validators.required),
      main_list_number_list: new FormControl(false, Validators.required),
      last_list_number_list: new FormControl(false, Validators.required),
      body_grid_revers: new FormControl(false, Validators.required),
      turn_padding_top: new FormControl(true, Validators.required),
      turn_padding_bottom: new FormControl(true, Validators.required),
      first_text_grid_revers: new FormControl(false, Validators.required),
      body_text_grid_revers: new FormControl(false, Validators.required),
      last_text_grid_revers: new FormControl(false, Validators.required),
      background_exists: new FormControl(false, Validators.required),
      first_list_grid_quantity_columns: new FormControl(
        '1',
        Validators.required
      ),
      main_list_grid_quantity_columns: new FormControl(
        '1',
        Validators.required
      ),
      last_list_grid_quantity_columns: new FormControl(
        '1',
        Validators.required
      ),
    }),
  },

  [FormTemplates.PRICES_BLOCK]: {
    type_block: new FormControl(FormTemplates.PRICES_BLOCK),
    headline: new FormControl('Headline', Validators.required),
    description: new FormControl(
      "The best and reasonable locksmith prices on the market for locksmith services are our top priority. Give us a call to get a free estimate for your locksmith's needs, whether it is an emergency or regular.\nFind out our starting prices for the most popular locksmith services, or contact us for more detailed information.",
      Validators.required
    ),
    list: new FormControl(
      'Lorem ipsum##-##$47-55*\nLorem ipsum##-##$47-55*\nLorem ipsum##-##$47-55*\nLorem ipsum##-##$47-55*\nLorem ipsum##-##$47-55*',
      Validators.required
    ),
    body_text: new FormControl(
      '*The final price may be different. A more accurate price will be provided depending on the specifics of the requested service, hour, service area and emergency.',
      Validators.required
    ),
    last_text: new FormControl(
      'Lorem ipsumrepellendus tempora, odio at, ut hic magnam earum tenetur praesentium distinctio placeat nihil amet id?',
      Validators.required
    ),
    styles: new FormGroup({
      add_top_need_more_info_button: new FormControl(
        false,
        Validators.required
      ),
      add_bottom_need_more_info_button: new FormControl(
        false,
        Validators.required
      ),
    }),
  },
  [FormTemplates.YOU_MAY_ALSO_LIKE]: {
    type_block: new FormControl(FormTemplates.YOU_MAY_ALSO_LIKE),
    headline: new FormControl('You may also like', Validators.required),
    list: new FormControl(
      'the-benefits-of-hiring-a-professional-home-security-company\nthe-benefits-of-hiring-a-professional-home-security-company\nthe-benefits-of-hiring-a-professional-home-security-company',
      Validators.required
    ),
  },
  /* ======================== LOCATIONS ======================== */
  [FormTemplates.META_INFOS_LOCATION]: {
    type_block: new FormControl(FormTemplates.META_INFOS_LOCATION),
    meta_tag_title: new FormControl('', Validators.required),
    meta_tag_description: new FormControl('', Validators.required),
    global_script: new FormControl(''),
    schema_script: new FormControl(''),
    name: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
  },
  [FormTemplates.ABOUT_US]: {
    type_block: new FormControl(FormTemplates.ABOUT_US),
    headline: new FormControl('A few words about Sure Lock & Key'),
    description: new FormControl(
      'Sure Lock & Key is a professional locksmith company that deals with all lock and key problems. The company was founded by four brothers with over 35 years of experience in the industry and operating daily on three core principles of professional services, affordable prices, and individual approach! \n We aim to satisfy every customer, so always do everything we can for your full comfort and pleasure. We know our business well, and we certainly know our valued customers too. We’re not just the best locksmith company in Bristol, we here at Sure Lock & Key are a family… and we strive to ensure that you will feel like an integral part of that family in all your associations and experiences with ourselves. The highest standards and happy customers are what we’re all about.'
    ),
  },
  [FormTemplates.ABOUT_CITY]: {
    type_block: new FormControl(FormTemplates.ABOUT_CITY),
    headline: new FormControl('About Bristol'),
    description: new FormControl(
      `From its early colonial settler days when it was incorporated as part of Farmington in 1645 to its formal incorporation as a town in its own right in 1911, the charming town of Bristol has a legacy of hitting above its weight over the years and to this very day. \n It was a pioneer in the production of wooden moment clocks, made by local merchant Gideon Roberts and sons, which were sold all over the country from the late 18th century onward. It is also home to Lake Compounce, the oldest operating theme park in the U.S. which opened its doors back in 1846. And today, it is probably best known for being home to the cable television sports channel ESPN. \n From humble beginnings, Gideon Roberts and his sons built up and created one of the first production-line clock manufacturers in America. \n We at Sure Lock & Key are very similar in that we too are a family business that started from humble beginnings with a vision and determination… and through hard work, innovation, and an unshakeable belief in putting you, our valued customer, first in everything we do, we’ve grown to become the preeminent 24-hour locksmith service delivering the very highest standard of locksmith services at the very lowest locksmith prices.`
    ),
  },
  [FormTemplates.OUR_SERVICES]: {
    type_block: new FormControl(FormTemplates.OUR_SERVICES),
    description: new FormControl(
      'Sure Lock & Key proposes a lot of benefits for customers. The most skilled technicians are ready to find the best solution for every locksmith situation. The best available prices in the current marketplace for the highest quality services are waiting for you as our customer. Moreover, we deliver emergency mobile locksmith services 24 hours a day, 365 days a year.Look what we offer you in delivering the best locksmith services in Bristol:'
    ),
    list: new FormControl(
      'Lock repair, replacement, and installation services, lock rekeying \nAll car key cutting and duplication \nMaster key systems, broken key extraction \nMailbox lock rekey / change \nHome, business, and car lockout services \nDeadbolt installation \nJammed ignition repair \nAll car key transponder (re) programming and recoding \nSafe & Vault lockout, combination set up, and change \nNew car key cutting',
      Validators.required
    ),
  },
  [FormTemplates.TWENTY_FOUR_HOUR]: {
    type_block: new FormControl(FormTemplates.TWENTY_FOUR_HOUR),
    headline: new FormControl('24-hour locksmith services in Hampton, CT'),
    description: new FormControl(
      'Locksmith problems never come in proper time, so Sure Lock & Key proposes you 24hr services! We have lots of mobile locations, so our skilled and experienced technicians can find you everywhere and propose immediate help in fixing all locksmith troubles!If you are still asking your friends: “Where can I find a 24-hour locksmith near me?” you should find Sure Lock & Key because we can assist you. We offer a range of 24-hour locksmith services for any locksmith requirements you may have, including:'
    ),
    list: new FormControl(
      `Lock repair, replacement, and installation \nCar key copy and duplication \nHome and house lockout services \nHome alarm systems \nEmergency car lockout services \nMotorcycle unlock services \nBroken key extraction \nRekeying services`
    ),
  },
  [FormTemplates.TESTIMONIALS]: {
    type_block: new FormControl(FormTemplates.TESTIMONIALS),

    description: new FormControl(''),
  },
};
