<!-- <dropdown name="Meta tags and scripts"> -->
<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Meta tag title </mat-label>
    <input matInput type="text" placeholder="Type tag title headline here" formControlName="meta_tag_title" />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Meta tag description </mat-label>
    <textarea matInput type="text" cdkTextareaAutosize placeholder="Type meta tag description here"
      formControlName="meta_tag_description"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Url for blog </mat-label>
    <input matInput type="text" placeholder="Type url for blog here" formControlName="url" />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Redirect url </mat-label>
    <input matInput type="text" placeholder="Type redirect url here" formControlName="redirect_url" />
  </mat-form-field>

  <mat-form-field>
    <mat-label>Categoric</mat-label>

    <mat-select placeholder="Select categoric" formControlName="categoric">
      <mat-option value="Car key replacement">Car key replacement</mat-option>
      <mat-option value="Safes">Safes</mat-option>
      <mat-option value="Residential">Residential</mat-option>
      <mat-option value="Commercial">Commercial</mat-option>
      <mat-option value="Automotive">Automotive</mat-option>
      <mat-option value="Security">Security</mat-option>
      <mat-option value="All about Locksmiths">All about Locksmiths</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Global script </mat-label>
    <textarea matInput type="text" cdkTextareaAutosize placeholder="Type global scripts here"
      formControlName="global_script"></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Schema script </mat-label>
    <textarea matInput type="text" cdkTextareaAutosize placeholder="Type schema scripts here"
      formControlName="schema_script"></textarea>
  </mat-form-field>
</form>
<!-- </dropdown> -->