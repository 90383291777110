import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-main-block-fields',
  templateUrl: './main-block-fields.component.html',
  styleUrls: ['./main-block-fields.component.scss'],
})
export class MainBlockFieldsComponent {
  @Output() removeItem = new EventEmitter<number>();

  constructor(public readonly controlContainer: ControlContainer) {}

  public removeBlock() {
    this.removeItem.emit();
  }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control;
  }
}
