<div class="fields-control-resize" resize [min]="300" [max]="800">
  <div class="wrap-drop-down" cdkDropList (cdkDropListDropped)="drop($event)">
    <ng-container
      *ngFor="let group of form; let i = index"
      [ngSwitch]="getTypeByFrom(group)"
    >
      <dropdown
        (toggleContentChanges)="toggleId(i)"
        [showContent]="id === i"
        name="Meta tags and scripts"
        *ngSwitchCase="form_templates.META_INFOS"
        [show_remove_icon]="false"
      >
        <meta-tag-and-scripts-fields [formGroup]="group" />
      </dropdown>

      <dropdown
        (toggleContentChanges)="toggleId(i)"
        [showContent]="id === i"
        name="Meta tags and scripts"
        *ngSwitchCase="form_templates.META_INFOS_LOCATION"
        [show_remove_icon]="false"
      >
        <app-meta-infos-fields [formGroup]="group" />
      </dropdown>

      <div class="drag-container" cdkDrag [cdkDragDisabled]="!!id">
        <div class="placeholder-drag" *cdkDragPlaceholder></div>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Main Block"
          *ngSwitchCase="form_templates.MAIN_BLOCK"
          (removeItem)="removeItem(i)"
        >
          <app-main-block-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Block with image"
          *ngSwitchCase="form_templates.BLOCK_WITH_IMAGE_LEFT"
          (removeItem)="removeItem(i)"
        >
          <block-with-image-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Prices block"
          *ngSwitchCase="form_templates.PRICES_BLOCK"
          (removeItem)="removeItem(i)"
        >
          <prices-block-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="You may also like"
          *ngSwitchCase="form_templates.YOU_MAY_ALSO_LIKE"
          (removeItem)="removeItem(i)"
        >
          <you-may-also-like [formGroup]="group" />
        </dropdown>

        <!--====================== location ======================-->
        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="About city"
          *ngSwitchCase="form_templates.ABOUT_CITY"
          (removeItem)="removeItem(i)"
        >
          <app-about-city-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="About us"
          *ngSwitchCase="form_templates.ABOUT_US"
          (removeItem)="removeItem(i)"
        >
          <app-about-us-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Our services"
          *ngSwitchCase="form_templates.OUR_SERVICES"
          (removeItem)="removeItem(i)"
        >
          <app-our-services-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Twenty four hours"
          *ngSwitchCase="form_templates.TWENTY_FOUR_HOUR"
          (removeItem)="removeItem(i)"
        >
          <app-twenty-four-hour-fields [formGroup]="group" />
        </dropdown>
        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Testimonials"
          *ngSwitchCase="form_templates.TESTIMONIALS"
          (removeItem)="removeItem(i)"
        >
          <app-testimonials-fields [formGroup]="group" />
          <!-- <app-twenty-four-hour-fields [formGroup]="group" /> -->
        </dropdown>

        <!--====================== vacancy ======================-->

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Vacancy Meta Info"
          *ngSwitchCase="form_templates.VACANCY_INFO"
          [show_remove_icon]="false"
        >
          <vacancies_meta_info [formGroup]="group" />
          <!-- <app-meta-infos-fields [formGroup]="group" /> -->
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Vacancy Main Block"
          *ngSwitchCase="form_templates.VACANCY_MAIN_BLOCK"
          [show_remove_icon]="false"
        >
          <vacancy-main-block-fields [formGroup]="group" />
        </dropdown>

        <dropdown
          (toggleContentChanges)="toggleId(i)"
          [showContent]="id === i"
          name="Vacancy Body Block"
          *ngSwitchCase="form_templates.VACANCY_BODY_BLOCK"
          (removeItem)="removeItem(i)"
        >
          <body_block_fields_component [formGroup]="group" />
        </dropdown>

        <!-- <dropdown
        (toggleContentChanges)="toggleId(i)"
        [showContent]="id === i"
        name="Block with image"
        *ngSwitchCase="form_templates.BLOCK_WITH_IMAGE_LEFT"
        (removeItem)="removeItem(i)"
      >
        <block-with-image-fields [formGroup]="group" />
      </dropdown> -->
      </div>
    </ng-container>

    <button mat-stroked-button type="button" class="btn" (click)="openDialog()">
      Add new block
    </button>
  </div>
</div>
