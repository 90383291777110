import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';
import { ConstructorAbstractService } from '../../../constructor.abstract.service';

@Component({
  selector: 'template-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss'],
})
export class TemplateItemComponent {
  @Output('publish') publishChanges = new EventEmitter<{
    id: string;
    posted: boolean;
  }>();
  @Output('remove') removeChanges = new EventEmitter<{
    id: string;
  }>();

  @Input() templateDto: {
    name: string;
    description: string;
    url: string;
    id: string;
    edit_path: string;
    is_posted: boolean;
  };
  constructor(
    public dialog: MatDialog,
    private readonly constructorAbstractService: ConstructorAbstractService
  ) {}

  public removeItem(id: string) {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        title: this.constructorAbstractService.type.toLowerCase(),

        onDelete: () => {
          this.constructorAbstractService.removeItem(id);
          this.removeChanges.emit({ id });
        },
      },
      maxWidth: '590px',
      width: '100%',
    });
  }

  public onPublish(posted: boolean) {
    this.publishChanges.emit({ id: this.templateDto.id, posted });
  }
}
