<!-- <dropdown name="Meta tags and scripts"> -->
<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Meta tag title </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type tag title headline here"
      formControlName="meta_tag_title"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Meta tag description </mat-label>
    <textarea
      matInput
      type="text"
      cdkTextareaAutosize
      placeholder="Type meta tag description here"
      formControlName="meta_tag_description"
    ></textarea>
  </mat-form-field>

  <!-- <mat-form-field>
    <mat-label> Vacancy title </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type vacancy title here"
      formControlName="vacancy_title"
    />
  </mat-form-field> -->

  <!-- <mat-form-field>
    <mat-label> Vacancy description </mat-label>
    <textarea
      matInput
      type="text"
      cdkTextareaAutosize
      placeholder="Type vacancy description here"
      formControlName="vacancy_description"
    ></textarea>
  </mat-form-field> -->

  <mat-form-field id="12">
    <mat-label>Country</mat-label>

    <mat-select placeholder="Select country" formControlName="country">
      <mat-option value="usa">USA</mat-option>
      <mat-option value="ukraine">Ukraine</mat-option>
      <mat-option value="israel">Israel</mat-option>
      <mat-option value="georgia">Georgia</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="312" *ngIf="country === 'usa'">
    <mat-label>State</mat-label>

    <mat-select placeholder="Select state" formControlName="state">
      <mat-option value="CT">CT</mat-option>
      <mat-option value="NY">NY</mat-option>
      <mat-option value="AZ">AZ</mat-option>
      <mat-option value="RI">RI</mat-option>
      <mat-option value="TX">TX</mat-option>
      <mat-option value="IL">IL</mat-option>
      <mat-option value="FL">FL</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Salary </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type salary here"
      formControlName="salary"
    />
  </mat-form-field>
</form>
<!-- </dropdown> -->
