import { gql } from 'apollo-angular';
import { ConstructedBlock } from 'src/app/entity/constructed-pages/constructed-block.entity';
import { ConstructedMetaInfo } from 'src/app/entity/constructed-pages/constructed-meta-info.entity';
import { ConstructedPage } from 'src/app/entity/constructed-pages/constructed-page.entity';
import { ConstructedPreview } from 'src/app/entity/constructed-pages/constructed-preview.entity';
import { ConstructedPageType } from 'src/app/enum/constructed-page-type.enum';

export const UPDATE_CONSTRUCTED_PAGE = gql`
  mutation updateConstructedPage($constructedPage: UpdateConstructedPageDto!) {
    updateConstructedPage(constructed_page: $constructedPage) {
      blocks {
        body_text
        constructed_page_id
        constructed_photo_id
        description
        first_list
        headline
        id
        last_list
        last_text
        list
        photo {
          alt
          file {
            format
            id
          }
          file_id
          id
          title
        }
        position_block
        styles
        text
        text_left
        text_right
        type_block
      }
      id
      is_posted
      meta_info {
        categoric
        constructed_page_id
        date_when_will_post_blog
        global_script
        id
        meta_tag_description
        meta_tag_title
        schema_script
        url
        redirect_url
        phone
      }
      post_date
      preview {
        constructed_page_id
        constructed_photo_id
        date
        description
        headline
        id
        type_block
        photo {
          alt
          file {
            format
            id
          }
          file_id
          id
          title
        }
      }
      type
    }
  }
`;

export interface IUpdateConstructedPageDto {
  constructedPage: Partial<{
    id: string;
    delete_blocks_ids: string[];
    blocks: DeepOmit<
      ConstructedBlock,
      'constructed_page_id' | 'constructed_photo_id' | 'id'
    >[];
    is_posted: boolean;
    preview: DeepOmit<
      ConstructedPreview,
      'constructed_page_id' | 'constructed_photo_id'
    >;
    type: ConstructedPageType;
    meta_info: DeepOmit<
      ConstructedMetaInfo,
      'constructed_page_id' | 'constructed_photo_id' | 'id'
    >;
  }>;
}

export interface IUpdateConstructedPageResponse {
  updateConstructedPage: ConstructedPage;
}
