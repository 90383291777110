import { Component, EventEmitter, Output } from '@angular/core';
import { ControlContainer, AbstractControl } from '@angular/forms';

@Component({
  selector: 'vacancy-main-block-fields',
  templateUrl: './vacancy-main-block-fields.component.html',
  styleUrls: ['./vacancy-main-block-fields.component.scss'],
})
export class VacancyMainBlockFieldsComponent {
  @Output() removeItem = new EventEmitter<number>();

  constructor(public readonly controlContainer: ControlContainer) {}

  public get control(): AbstractControl<any, any> | null {
    console.log(this.controlContainer.control);

    return this.controlContainer.control;
  }
}
