export enum ConstructedPageType {
  BLOG = 'BLOG',
  LOCATION = 'LOCATION',
  VACANCY = 'VACANCY',
}

export const reversed_lover_case_constructed_page_type = {
  blog: ConstructedPageType.BLOG,
  location: ConstructedPageType.LOCATION,
  vacancy: ConstructedPageType.VACANCY,
};
