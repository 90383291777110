import { Component, Input, SimpleChanges } from '@angular/core';
import { BlockStyles } from '../../blogs/block-with-image/block-with-image.component';

@Component({
  selector: 'body-block',
  templateUrl: './body-block.component.html',
  styleUrls: ['./body-block.component.scss'],
})
export class BodyBlockComponent {
  @Input() data: BlockWithImageData;

  public parsed_styles?: BlockStyles;
  public img_exist: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.parsed_styles = this.data.styles;
    this.img_exist =
      this.data.photo.file instanceof File || !!this.data.photo.file_id;
  }

  public compareTwoPhotos(first: File, second: File) {
    if (second && first) {
      const reader1 = new FileReader();
      const reader2 = new FileReader();

      reader1.readAsDataURL(first);
      reader2.readAsDataURL(second);

      return reader1.result === reader2.result;
    }
    return false;
  }

  get CSSVariables() {
    if (this.parsed_styles) {
      const { background_exists, turn_padding_top, turn_padding_bottom } =
        this.parsed_styles;

      return `
        --background-color: ${background_exists ? '#F7F7F7' : '#FFFFFF'};
        --grid-column-start: ${this.img_exist ? 'unset' : '1'};
        --grid-column-end:  ${this.img_exist ? 'unset' : '3'};
        --padding-top: ${turn_padding_top ? '50px' : '0px'};
        --padding-bottom: ${turn_padding_bottom ? '50px' : '0px'};
        --padding-top-mobile: ${turn_padding_top ? '42px' : '0px'};
        --padding-bottom-mobile: ${turn_padding_bottom ? '42px' : '0px'};
      `;
    } else return '';
  }

  get css_variables_first_list() {
    const { first_list_grid_quantity_columns = '1', first_text_grid_revers } =
      this.parsed_styles!;
    return `
      --grid-template-columns: repeat(${
        first_list_grid_quantity_columns || '1'
      }, 1fr);
      --grid-row: ${first_text_grid_revers ? '1' : 'unset'};
    `;
  }

  get css_variables_main_list() {
    const { main_list_grid_quantity_columns = '1', body_text_grid_revers } =
      this.parsed_styles!;
    return `
      --grid-template-columns: repeat(${
        main_list_grid_quantity_columns || '1'
      }, 1fr);
      --grid-row: ${body_text_grid_revers ? '1' : 'unset'};
    `;
  }

  get css_variables_last_list() {
    const { last_list_grid_quantity_columns = '1', last_text_grid_revers } =
      this.parsed_styles!;
    return `
      --grid-template-columns: repeat(${
        last_list_grid_quantity_columns || '1'
      }, 1fr);
      --grid-row: ${last_text_grid_revers ? '1' : 'unset'};
    `;
  }
}

interface BlockWithImageData {
  headline: string;
  description: string;
  body_text: string;
  photo: {
    file?: File;
    file_id?: string;
    alt?: string;
    title?: string;
  };
  styles?: BlockStyles;
  list: string;
  last_text?: string;
  last_list?: string;
  first_list?: string;
}
