import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompaniesService } from '../../companies.service';
import { DeleteDialogComponent } from 'src/app/modules/ui-kit/delete-item-popup/component/delete-dialog.component';
import { ConstructedCompany } from 'src/app/entity/constructed-pages/constructed-companies.entity';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Component({
  selector: 'company-card',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss'],
})
export class CompanyCardComponent {
  public is_open: boolean = false;
  @Input() company: ConstructedCompany;

  constructor(
    public dialog: MatDialog,
    private readonly companiesService: CompaniesService,
    private readonly alertService: AlertService
  ) {}

  public copyId(id: string) {
    navigator.clipboard.writeText(id);
    this.alertService.alert({
      type: 'success',
      message: 'Successfully copied',
    });
  }

  public openUpdateCompanySideMenu() {
    this.is_open = true;
  }
  public get vacancy() {
    return this.company.name.includes('slk-global');
  }
  public onUpdate(company_dto: Omit<ConstructedCompany, 'id'>) {
    this.companiesService.updateCompagnie({
      company_dto: { ...company_dto, id: this.company.id },
    });
  }

  public deleteCompany(id: string) {
    this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'information',
        onDelete: () => {
          this.companiesService.deleteCompany({ id });
        },
      },
      maxWidth: '590px',
      width: '100%',
    });
  }
}
