import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConstructorAbstractService } from '../../constructor.abstract.service';
import { ConstructorService } from '../../constructor.service';
import { DialogModalComponent } from '../blocks-modal/blocks-modal.component';
import { FormTemplates } from './fields-control.form.template';

@Component({
  selector: 'fields-control',
  templateUrl: './fields-control.component.html',
  styleUrls: ['./fields-control.component.scss'],
})
export class FieldsControl {
  public id: number | null = null;

  public drag: boolean = false;
  constructor(
    public dialog: MatDialog,
    private readonly constructorAbstractService: ConstructorAbstractService,
    private readonly constructorService: ConstructorService
  ) { }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.form, event.previousIndex, event.currentIndex);
    this.unset_id();
  }

  public unset_id() {
    this.id = null;
  }

  public get form() {
    return this.constructorAbstractService.form;
  }

  public get form_templates(): typeof FormTemplates {
    return FormTemplates;
  }

  public getTypeByFrom(form: FormGroup) {
    return form.value.type_block;
  }

  public toggleId(id: number) {
    if (this.id === id) this.unset_id();
    else this.id = id;
  }

  public openDialog() {
    this.unset_id();
    this.dialog.open(DialogModalComponent, {
      data: {
        createBlock: (type: FormTemplates) => this.createBlock(type),
        blocks_preview: this.constructorAbstractService.blocks_preview,
      },
    });
  }

  public removeItem(index: number) {
    this.unset_id();
    this.constructorAbstractService.form =
      this.constructorAbstractService.form.filter((_, i) => i !== index);
  }

  private async createBlock(type: FormTemplates) {
    this.unset_id();
    const block = await this.constructorService.addBlock(type);
    this.constructorAbstractService.form.push(block);
  }
}
