<div class="title_wrap">
  <h1>Vacancies</h1>
  <a routerLink="/slk-hr/vacancy/create-new">
    <button mat-raised-button>Create</button>
  </a>
</div>

<mat-tab-group animationDuration="0">
  <mat-tab label="Published">фв </mat-tab>
  <mat-tab label="Created"> </mat-tab>
</mat-tab-group>
