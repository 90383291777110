import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConstructedPagesModule } from './pages/constructed-pages/pages/constructed-pages.module';
import { CreateConstructedPageModule } from './pages/constructed-pages/pages/create-constructed-page.module';
import { UpdateConstructedPageModule } from './pages/constructed-pages/pages/update-constructed-pages.module';
import { ConfirmedLocksmithModule } from './pages/confirmed/confirmed-locksmith/confirmed-locksmith.module';
import { ConfirmedModule } from './pages/confirmed/confirmed.module';
import { CreateNewLocksmithModule } from './pages/create-new-locksmith/create-new-locksmith.module';
import { RequestLocksmithModule } from './pages/requests/request-locksmith/request-locksmith.module';
import { RequestsModule } from './pages/requests/requests.module';
import { ReviewsMainModule } from './pages/reviews-main/reviews-main.module';
import { CompaniesModule } from './pages/companies/companies.module';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    CompaniesModule,
    // RequestLocksmithModule,
    // ConfirmedLocksmithModule,
    ConstructedPagesModule,
    CreateConstructedPageModule,
    UpdateConstructedPageModule,
    // CreateNewLocksmithModule,
    // ReviewsMainModule,
    // ConfirmedModule,
    // RequestsModule,
  ],
  declarations: [],
})
export class SlkAdminModule {}
