import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstructorAbstractService } from '../constructor.abstract.service';
import { ConstructorService } from '../constructor.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalConstructorComponent } from './error-modal/error-modal.component';

@Component({
  selector: 'constructor-blocks',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.scss'],
})
export class ConstructorComponent implements OnInit, OnDestroy {
  public headline: string = '';

  constructor(
    private readonly constructorAbstractService: ConstructorAbstractService,
    protected readonly constructorService: ConstructorService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const data = localStorage.getItem('formData');

    this.route.paramMap.subscribe((params) => {
      const type = params.get('type');
      const page_id = params.get('page-id');
      const company_id = params.get('company-id');

      this.constructorAbstractService.configPageOnLoad(
        page_id,
        type,
        company_id
      );
    });

    const values = JSON.parse(data!);

    data &&
      values.map((element: any) => {
        this.constructorService.patchValue({
          block: element,
          type_block: element.type_block,
          form: this.constructorAbstractService.form,
        });
      });
    localStorage.removeItem('formData');

    this.route.data.subscribe((data) => {
      const { headline = '' } = data;
      this.headline = headline;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  saveDataBeforeUnload(event: Event): void {
    const data = this.constructorAbstractService.form.map((e) => {
      return e.value;
    });
    data.length > 0 && localStorage.setItem('formData', JSON.stringify(data));
  }

  ngOnDestroy() {
    console.log('here');
  }

  public onSave() {
    const invalid = this.constructorService.checkValidity(
      this.constructorAbstractService.form
    );
    if (invalid) {
      this.dialog.open(ErrorModalConstructorComponent, {
        maxWidth: '590px',
        width: '100%',
      });
    } else {
      this.constructorAbstractService.onSave();
    }
  }

  public get type() {
    return this.constructorAbstractService.type;
  }

  public get loading() {
    return this.constructorAbstractService.loading;
  }
}
